<template>
  <div class="app-container h flex">
    <el-tree ref="ents" :data="enterprises" node-key="id" :props="{label: 'name'}" default-expand-all highlight-current :expand-on-click-node="false" @current-change="handleCurrentChange" style="width: 180px; margin-right: 20px; border-radius: 4px; border: #e7e7e7 solid 1px; overflow: hidden;" v-if="showSubs" />
    <div class="flex v">
      <div class="head-container" v-if="current && current.id === user.entId">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>

      <el-table :data="data" row-key="id" highlight-current-row default-expand-all height="200px">
        <el-table-column prop="name" label="区域名称" min-width="180" show-overflow-tooltip />
        <el-table-column prop="info" label="区域描述" min-width="360" show-overflow-tooltip />
        <el-table-column v-if="current && current.id === user.entId" width="100" fixed="right">
          <div class="row-commands" slot-scope="scope">
            <el-button size="mini" type="text" @click.stop="edit(scope.row)">编辑</el-button>
            <el-button :disabled="!!scope.row.children && !!scope.row.children.length" type="text" class="danger" size="mini" @click.stop="subDelete(scope.row)">删除</el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <eForm ref="form" :is-add="isAdd" @submit="handleFormSubmit" v-if="data" />
  </div>
</template>


<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { del } from "@/api/area";
import { getSub } from "@/api/sysEnterprise";
import eForm from "./form";
import { mapGetters } from "vuex";

export default {
  name: "Category",
  components: { eForm },
  mixins: [initData],
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    let iw = Math.round(document.body.offsetWidth * 0.4);
    if (iw < 240) iw = 240;
    return {
      current: null,
      delLoading: false,
      showSubs: false,
      enterprises: [],
      infoWidth: iw,
    };
  },
  created() {
    getSub().then((res) => {
      this.showSubs = res.length > 0;
      let d = {
        id: this.user.entId,
        name: this.user.entName,
        children: res,
      };
      this.enterprises = [d];
      this.current = d;
      this.$nextTick((_) => {
        this.$refs.ents && this.$refs.ents.setCurrentKey(this.current.id);
      });
    });
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/area";
      this.size = 1000000;
      this.params.sort = "name,asc";
      if (this.current) this.params.entId = this.current.id;
      else delete this.params.entId;
      if (this.query.keyword) this.params.name = this.query.keyword;
      else delete this.params.name;
      return true;
    },
    afterLoad(data) {
      return this.$buildTree(data, null);
    },
    handleCurrentChange(row) {
      if (!this.current || this.current.id !== row.id) {
        this.current = row;
        this.toQuery();
      }
    },
    handleFormSubmit(isAdd, d) {
      this.toQuery();
    },
    subDelete(row) {
      this.$confirm("您确定要删除该地区吗？", "操作确认", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then((_) => {
        del(row.id).then((res) => {
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
          this.toQuery();
        });
      });
    },
    add() {
      this.isAdd = true;
      this.$refs.form.resetForm(
        null,
        this.query.entId ? this.query.entId : this.user.entId
      );
    },
    edit(data) {
      this.isAdd = false;
      let cd = JSON.parse(JSON.stringify(data));
      delete cd.children;
      this.$refs.form.resetForm(cd);
    },
  },
};
</script>